import { LitElement, html, css } from 'lit';
import { KalePage } from '../shared-components/page.js';
import XLSX from 'xlsx';
import '../shared-components/progress-circle.js';
import {  client } from '../queries/queries.js';
import gql from 'graphql-tag';
import { coltypes, xlsx_formatters, XLSX_NULL } from '../components/report-components.js';
// SELECT pid, pg_terminate_backend(pid) FROM pg_stat_activity WHERE state = 'active' and query not ilike '%MAGIC%';

//import { reports } from '../mindues-app/report-defs.js';

const report_view_styles = css`
        report-table {
          opacity: 0;
        }
        report-table[show] {
          opacity: 1;
        }
`;
/*
const findMatchingReportDef = ({matched_path, report}) => {
     if (!(matched_path || report)) return;
     let path = (matched_path ? matched_path : "").toLowerCase().replace(/[^a-z]/g, '');
     let name = (report ? report : "").toLowerCase().replace(/[^a-z]/g, '');
     const match_reports = reports.map(r => ({match_string: r.name.toLowerCase().replace(/[^a-z]/g, ''), report: r}));
     const match = match_reports.find(r => r.match_string === path || r.match_string === name);
     if (match) return match.report;
     //return reports.find(r => r.title === matched_path || r.name.toLowerCase().replace(/[^a-z]/g, '') === matched_path || r.title === name || r.name.toLowerCase().replace(/[^a-z]/g, '') === name);
  }
  */

class ReportSheet extends LitElement {
  static styles = [ report_view_styles]
  //static icon = "table_chart"
  //static default_title = "Report"

  constructor() {
    super();
    this.done = 0;
    this.time_per_chunk = 4;

    this.report = {};
    this.limit = 100;
    this.offset = undefined;
  }

  static get properties() {
    return {
      ...super.properties,
      report: { type: Object },
    };
  }

  get period_id() { return this._period_id }
  set period_id(p) { 
    if (p !== this._period_id) {
      this._period_id = p;
      this.refetchReportData();
      this.requestUpdate('period_id');
    }
  }

  refetchReportData() {
      this.report = {
          ...this.report,
          data: [],
          args: [],
          sort: [],
          offset: 0,
          status: 'requested'
        };
      this.offset = undefined;
      this.moreData();
  }

  /*
  setTitle(name, args) {
      this.arguments = Object.keys(args).map(k => ({name: k, value: args[k]}));
      this.subtitle = `${Object.keys(args).map(k => `${k}=${args[k]}`).join(", ")}`;
      this.title = `${name} subtitle}]`
  }

  static sanitizeArgs(def, args) {
      let valid_args = new Set(Object.keys(def.args));
      let combined_args = {...def.args, ...args};
      let valid_keys = Object.keys(combined_args).filter(k => valid_args.has(k)).sort();
      return Object.fromEntries(valid_keys.map(k => [k, combined_args[k]]));
  }
  
  static constructAddress({protocol, host}, args) {
    let def = findMatchingReportDef(args);
    if (def) {
      let report_args = ViewReport.sanitizeArgs(def, args);
      let arg_names = Object.keys(report_args);
      let canon = `${protocol}//${host}/reports/${def.name.toLowerCase().replace(/ /g, '_')}${arg_names.length > 0 ? `?${arg_names.map(k => `${k}=${report_args[k]}`).join('&')}` : ''}`
      return canon;
    }
    console.warn("no report matched", args);
    return `${protocol}//${host}/reports`
  }*/

  firstUpdated() {
       this.scroller = this.renderRoot.getElementById('table');
       this.moreData();
    }

  render() {
    return html`<report-table id="table" }
        ?show=${this.report && true}
        .report=${this.report}
        @col-opts=${e => this.colOpts(this.report, e.detail)} @add-sort=${({ detail: col }) => this.addSort(this.report, col)}
        @more-data=${e => this.moreData(this.report)}
        @scroller=${e => this.scroller = e.detail}
        @scroll=${e => { this.dispatchEvent(new CustomEvent("scroll", {...e}))}}
        @save-field=${e => {this.saveField(e.detail)}}
    ></report-table >
  `;
  }
  saveField() {}
  get ready() {
    return this.report && this.report.status === 'ready'; //TODO: expiration
  }
  get processing() {
    return this.report && this.report.status === 'processing'; //TODO: expiration
  }
  get requested() {
    return this.report && this.report.status === 'requested'; //TODO: expiration
  }

  get icon_status() {
    if (this.ready) {
      return 'complete';
    }
    if (this.processing) {
      return 'complete';
    }
    if (this.requested) {
      return 'animate';
    }
    return 'incomplete'
  }

  prepButtonText(report) {
    switch (this.icon_status) {
      case 'complete':
        return 'prepared';
      case 'animate':
        return 'preparing...';
      case 'incomplete':
      default:
        return 'prepare';
    }
  }

  prepareTable() {
    this.report = { ...this.report, data: [], status: 'requested' }
  }

  getConditions() { return ''}
  getFilter() { return ''}

  get query() {
  }

  columns = [
    ];

  download_columns = [
    ];


  queryVars() {
    return {};
  }


  moreData(nolimit) {
    //console.log("get more for ", this.report.name, this.queryVars(), this.report.args, this.report.sort, performance.now());
    this.report = {...this.report};
    this.offset =  this.offset !== undefined ? this.offset + this.limit : 0;

    if (this.report.invalid) {
      this.report = { ...this.report, status: 'requested', offset: 0 };
    } else {
      this.report = { ...this.report, status: 'requested' };
    }

    let vars = this.queryVars();
    if (!vars?.period) {
      return;
    }

    client.query({
      fetchPolicy: 'network-only',
      query: this.query,
      variables: vars //this.report.filter, this.report.sort, nolimit ? 0 : this.report.limit, this.report.offset, this.report.args)
    })
      .then(data => {
        //console.log("got some data", data, vars)
        data = data.data.report;
        this.processData(data, nolimit, vars);
      })
      .catch(error => console.error(error));
  }
  dataFunc(raw_data, vars) {
    return raw_data;
  }


  idleProcess(deadline, processed, remaining, vars) {
    let time = deadline.timeRemaining();
    let start = performance.now();
    let chunk = 50;//Math.round(time / (report.time_per_row ? report.time_per_row : 4));
    while ((deadline.timeRemaining() || deadline.didTimeout) && remaining.length > 0) {
      let chunk_data = remaining.slice(0, chunk);
      remaining = remaining.slice(chunk);

      processed = [...processed, ...this.dataFunc(chunk_data, vars)];
      //processed.push(report.row_func(remaining.shift()));
    }
    if (remaining.length === 0) {
      this.report = {
        ...this.report,
        status: remaining.length > 0 ? 'processing' : 'ready',
        data: [...this.report.data ? this.report.data : [], ...processed],
        remaining: remaining.length,
      };
      processed = [];
    } else {
      this.report = { ...this.report, remaining: remaining.length };
    }

    let elapsed = performance.now() - start;
    let processed_rows = this.report.processed_rows ? this.report.processed_rows + chunk : chunk;
    let total_process_time = this.report.total_process_time ? this.report.total_process_time + elapsed : elapsed;
    this.report.processed_rows = processed_rows;
    this.report.total_process_time = total_process_time;
    this.report.time_per_row = this.report.processed_rows / this.report.total_process_time;
    if (remaining.length > 0) {
      this.handle = requestIdleCallback(d => this.idleProcess(d, processed, remaining, vars));
    } else {
      if (this.report.cb) this.report.cb();
      this.report.cb = null;
      //console.log(`idle: all done!`, this.report.data)
      this.requestUpdate("report");
    }
    //console.log(`had ${time}, tried ${chunk} (=${this.time_per_chunk}*${time}), took ${elapsed} = ${elapsed / chunk}/row`);
  }
  processData(data, nolimit, vars) {
    //console.log(`processing ${data.length} results...`, data)
    this.report = {
      ...this.report,
      status: 'processing',
      data: [...(this.report.data && !this.report.invalid ? this.report.data : [])],
      end: data.length < this.report.limit || nolimit,
      invalid: false
    };

    if (this.report.row_func) {
      this.handle = requestIdleCallback(deadline => this.idleProcess(deadline, [], data, vars));
    } else {
      this.report = {
        ...this.report,
        status: 'ready',
        data: [...this.report.data ? this.report.data : [], ...data],
        remaining: 0
      };
      if (this.report.cb) this.report.cb();
      this.report.cb = null;
      console.log(`process: all done!`, this.report.data)
      this.requestUpdate("report");
    }
  }

  colOpts({ sort, filter }) {
    let sort_cols = new Set(sort.map(s => s.col));
    this.report.sort = [...sort, ...this.report.sort.filter(s => !sort_cols.has(s.col))].filter(s => s.reverse !== undefined);

    let filters = new Set(filter.map(f => `${f.col}:::${f.op}`));
    this.report.filter = [...filter, ...this.report.filter.filter(f => !filters.has(`${f.col}:::${f.op}`))].filter(f => f.val !== undefined);

    this.offset = undefined;
    this.report.invalid = true;
    this.moreData();
    this.requestUpdate('report');
  }
  addSort(col) {
    let current = (this.report.sort ? this.report.sort : []); //.map((col, reverse) => ({col: col, num: reverse+0}));
    let old = current.find(s => s.col === col);
    let num = old ? old.reverse + 1 : 0;
    this.report.sort = [...[{ col: col, num: num }].filter(c => num < 2).map(({ col, num }) => ({ col: col, reverse: num > 0 })), ...current.filter(s => s.col !== col)];
    console.log(`${this.report.name}: new sort == ${col}== `);
    console.log(this.report.sort);
    this.offset = undefined;
    this.report.invalid = true;
    this.moreData();
    this.requestUpdate('report');
  }


  _saveXLSX(name, fields, data) {
    let all_data = [[...fields], ...data];
    const fitToColumn = (arrayOfArray) => arrayOfArray[0].map((a, i) => ({ wch: Math.min(Math.max(...arrayOfArray.map(a2 => a2[i] !== null && a2[i] !== undefined ? (typeof (a2[i]) === 'object' && a2[i].constructor.name === 'Date' ? a2[i].toLocaleDateString() : a2[i].toString()).length : 0)), 25) }));
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.aoa_to_sheet(all_data);

    let d2 = XLSX.utils.sheet_to_json(ws, { header: 1 });

    console.log("xlsx data", data);
    console.log("as json", d2);

    ws['!cols'] = fitToColumn(d2);
    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(wb, ws, name);
    console.log("XLS WB", wb);
    let d = new Date();
    let datestr = (d.toLocaleDateString() + '.' + d.toLocaleTimeString()).replace(/[: /]/g,'.');
    XLSX.writeFile(wb, `${name}-${datestr}.xlsx`);
    //XLSX.writeFileAsync(wb, name + ".xlsx", (error, result) => console.warn("WRITE COMPLETE", error, result));
  }


  downloadTable() {
    if (this.ready) {
      //this.report = this.reports.get(report.name);
      if (this.report.end) {
        let colinfo = [...(this.report.download_columns ? this.report.download_columns : this.report.columns).map(f => ({ ...f }))];
        let fields, data;
        if (colinfo.some(c => c.compare)) {
          // table has some comparisons
          console.log("XLSX input data:", this.report.data)
          colinfo.forEach(col => {
            col.need_compare = col.compare && this.report.data.some(row => row.id && !row[col.c + '_match']);
          });
          fields = colinfo.reduce((cols, col) =>
            col.need_compare
              ? [...cols,
              { field: col.c, sub: 'sys', name: `${col.c} [new]` },
              { field: col.c, sub: 'ref', name: `${col.c} [old]` },
              { field: col.c, sub: 'match', name: `${col.c} [match]` }
              ]
              : [...cols,
              col.compare ? { field: col.c, sub: 'sys', name: col.c } : { field: col.c, name: col.c }
              ], []);
          data = this.report.data.map(d => fields.map(f => f.sub ? d[f.field + '_' + f.sub] : d[f.field]));
          fields = fields.map(f => f.name);
        } else {
          console.log("COLINFO", colinfo);
          fields = colinfo.map(f => f.c);
          data = this.report.data.reduce((ret, d) => {
            let out = colinfo.map(({ c, t }) => xlsx_formatters[t](d[c], { c, t }, d))
            if (d.totals_for) {
              out[0] = `TOTAL ${d[d.totals_for]}`;
              return [...ret, out.map(o => XLSX_NULL), out, out.map(o => XLSX_NULL)];
            }
            return [...ret, out];
          }, []);
        }
        if (this.report.summary) {
          let s = [];
          this.report.summary.forEach(r => {
            s.push(xlsx_formatters[r.t](r.value));
            //s = s.map(x => ({...x, v: `<font><b/>${x.v}</font>`}))
            if (r.colspan) {
              [...Array(r.colspan-1)].forEach(a => s.push(XLSX_NULL));
            }
          })
          data.push(s.map(o => XLSX_NULL));
          data.push(s);
        }
        this._saveXLSX(`${this.report.name}${this.report.subname ? ' ' + this.report.subname(this.report.args) : ''}`, fields, data);
      } else {
        this.report.cb = () => this.downloadTable();
        this.moreData(true);
      }
    } else {
      this.report.cb = () => this.downloadTable();
      this.prepareTable();
    }
  }
}

//window.customElements.define('report-sheet', ReportSheet);

export class AffiliateReport extends ReportSheet {
  getConditions() { return ''}
  getFilter() { return ''}

  constructor() {
    super();

    this.report = {
      name: 'Affiliates',
      args: [],
      sort: [],
      limit: 50,
      offset: 0,
      row_func: true,
      columns: this.columns
    };
    this.limit = 500;
    this.offset = undefined;
  }

  get query() {
    return gql`
    query affiliate_report($limit: Int, $offset: Int, $period: Int) {
        report: md_v_report_affiliate_progress (
          ${this.getConditions()}
          offset: $offset, 
          limit: $limit,
          where: {_and: [
            {year: {_eq: $period}},
            ${this.getFilter()}
          ]}
        )
        {
          year
          state
          affiliate
          stat
          members
          members_not_in_negotiation
          complete
          average_increase
          total_stat
          total_members
          total_members_not_in_negotiation
          total_complete
          total_average_increase
          note
        }
      }
    
    `
  }

  columns = [
      { c: 'state', t: 'ltext' },
      { c: 'affiliate', t: 'ltext' },
      { c: 'stat', t: 'number' },
      { c: 'members', t: 'number' },
      { c: 'members_not_in_negotiation', t: 'number' },
      { c: 'complete', t: 'pct' },
      { c: 'average_increase', t: 'pct' },
      { c: 'note', t: 'ltext', edit_func: (d) => {console.log("saving", d)} },
    ];

  download_columns = [
      { c: 'state', t: 'ltext' },
      { c: 'affiliate', t: 'ltext' },
      { c: 'stat', t: 'number' },
      { c: 'members', t: 'number' },
      { c: 'members_not_in_negotiation', t: 'number' },
      { c: 'complete', t: 'pct' },
      { c: 'average_increase', t: 'pct' },
      { c: 'note', t: 'ltext' },
    ];


  saveNote(data, value) {
    console.log(`saving ${value} to ${data.affiliate}/${data.period_id}`);
    const mut = gql`
        mutation upsert_affiliate_notes($notes: [affiliate_notes_insert_input!]!) {
          notes:insert_affiliate_notes (
            objects: $notes,
            on_conflict: {
              constraint: affiliate_notes_pkey,
              update_columns: [note]
            }
          ) {
            returning {
              affiliate
              period_id
              note
            }
          }
        }
    `;
    client.mutate({
      mutation: mut,
      variables: {notes: [{
        affiliate: data.affiliate,
        period_id: data.period_id,
        note: value
      }]},
     }).then(data => {
       data?.data?.notes?.returning.forEach(n => {
         console.log("got note:", n); 
        this.report = {
        ...this.report,
        data: [...this.report.data.map(d => {
          if (d.affiliate === n.affiliate && d.period_id === n.period_id) {
            d.note = n.note;
          }
          return d;
        })]
        };
        this.requestUpdate('report');
       })
     }).catch(error => {
       this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { kind: 'error', text: `failed to save note` } })); 
     });
  }

  saveField({field, value, data}) {
    console.log(`SAVE FIELD=${field} val=${value} data=`, data)
    if (field === "note") {
      this.saveNote(data, value);
    }

  }

  queryVars() {
    return {period: this.period_id, offset: this.offset, limit: this.limit};
  }
  /*
affiliate: "L2021"
average_increase: null
complete: null
members: null
members_not_in_negotiation: null
period_id: 11
stat: 0
state: "TX"
total_average_increase: 0.29423600511202697
total_complete: 0.8255332208071199
total_members: 9415
total_members_not_in_negotiation: 8558
total_stat: 1140475

   { c: 'state', t: 'ltext' },
      { c: 'affiliate', t: 'ltext' },
      { c: 'stat', t: 'number' },
      { c: 'members', t: 'number' },
      { c: 'members_not_in_negotiation', t: 'number' },
      { c: 'complete', t: 'pct' },
      { c: 'average_increase', t: 'pct' },
    ];
  */


  dataFunc(raw_data) {

    //console.log("AFF DATA FUNC", raw_data);
    let {total_average_increase, total_complete, total_members, total_members_not_in_negotiation, total_stat} = raw_data[0];
    this.report = {...this.report, summary: [
      {value: 'GRAND TOTAL', colspan: 2, t: 'ctext'},
      {value: total_stat, t: 'number'},
      {value: total_members, t: 'number'},
      {value: total_members_not_in_negotiation, t: 'number'},
      {value: total_complete, t: 'pct'},
      {value: total_average_increase, t: 'pct'},
      {value: '', t: 'ctext'},
    ]
    }
    return raw_data.map(r => r);
  }
}

window.customElements.define('affiliate-report', AffiliateReport);

export class MasterReport extends ReportSheet {
  getConditions() { return ''}
  getFilter() { return ''}

  constructor() {
    super();

    this.report = {
      name: 'Masters',
      args: [],
      sort: [],
      limit: 50,
      offset: 0,
      row_func: true,
      columns: this.columns
    }

    this.limit = 500;
    this.offset = undefined;
  }

  get query() {
    console.log("query");
    /*
     * --create view md.v_report_master_agreements
select
'totals' as kind,
a.state, a.master, a.council, a.local, 
i.employer,
s.total_stat as stat,
--s.adjusted_members, 
s.total_adjusted_members,
--s.adjusted_members - s.adjusted_members_in_negot as adjusted_members_not_in_negotiation,
s.total_adjusted_members - s.adjusted_total_members_in_negot as adjusted_members_not_in_negotiation,
case when total_stat > 0 then 100.0*total_adjusted_members/(total_stat) else 0 end as complete,
case when total_adjusted_members > 0 then total_weighted / total_adjusted_members else 0 end as average_increase
--complete
--average_increase,
--s.*
from md.agreement a
left join 
md.vm_fast_increase_sums s on a.id=s.agreement_id
left join md.increase i on s.id=i.id
where a.is_master and a.year=2024 limit 10;
     * */
		/*
		 WIP:
		 select distinct
a.id as agreement_id,
--stat_compound,
ae.year as period_id,
--unit_id,
l.id as localid,
l.state,
a.master as master,
--i.master as inc_master, --extra
l.name,
--affiliate,
l.council,
l.local,
--subunit,
--employer,
--local_stat,
--stat_for_sum,
--stat_share,
--all_adj_share,
--all_negot_share,
--av_inc
sum(members) over (partition by al.agreement_id)--extra
from
md.agreement_local al
left join md.local l on al.localid=l.id
left join md.agreement a on al.agreement_id=a.id
left join md.agreement_entry ae on al.agreement_id=ae.agreement_id
left join md.increase i on ae.year=i.year and ae.agreement_id=i.agreement_id
where a.is_master
limit 500;*
		 *
		 *
		 *
		 * */
    return gql`
    query master($limit: Int, $offset: Int, $period: Int) {
        report: md_v_report_master_agreements (
          ${this.getConditions()}
          offset: $offset, 
          limit: $limit,
          where: {_and: [
            {year: {_eq: $period}},
            ${this.getFilter()}
          ]}
        )
        {
      kind
      year
      agreement_id
      local_id
      state
      master
      council
      local
      name:employer
      stat
      total_adjusted_members
      adjusted_members_not_in_negotiation
      complete
      average_increase
        }
      }
    
    `
          // agreement_id
          // stat_compound
          // period_id
          // unit_id
          // unit_id
          // localid
          // state
          // master
          // name
          // affiliate
          // council
          // local
          // subunit
          // employer
          // local_stat
          // stat_for_sum
          // stat_share
          // all_adj_share
          // all_negot_share
          // av_inc
  }

  columns = [
      { c: 'master', t: 'ltext', total: true},
      { c: 'state', t: 'ltext' },
      { c: 'council', t: 'ltext' },
      { c: 'local', t: 'ltext' },
      //{ c: 'subunit', t: 'ltext' },
      { c: 'name', t: 'ltext' },
      { c: 'stat', t: 'number' },
      { c: 'members', t: 'number' },
      { c: 'members_not_in_negotiation', t: 'number' },
      { c: 'complete', t: 'pct' },
      { c: 'average_increase', t: 'pct' },
    ];

  download_columns = [
      { c: 'master', t: 'ltext' ,total: true},
      { c: 'state', t: 'ltext' },
      { c: 'council', t: 'ltext' },
      { c: 'local', t: 'ltext' },
      // { c: 'subunit', t: 'ltext' },
      { c: 'name', t: 'ltext' },
      { c: 'stat', t: 'number' },
      { c: 'members', t: 'number' },
      { c: 'members_not_in_negotiation', t: 'number' },
      { c: 'complete', t: 'pct' },
      { c: 'average_increase', t: 'pct' },
    ];

  queryVars() {
    console.log("master report queryvars: ", {period: this.period_id, offset: this.offset, limit: this.limit});
    return {period: this.period_id, offset: this.offset, limit: this.limit};
  }

      /* kind
      year
      agreement_id
      local_id
      state
      master
      council
      local
      name
      stat
      total_adjusted_members
      adjusted_members_not_in_negotiation
      complete
      average_increase */
  dataFunc(raw_data, vars) {
    console.warn("MASTER DATA FUNC", raw_data);
    return raw_data.map(({kind, state, master, council, local, name, stat, total_adjusted_members, adjusted_members_not_in_negotiation, complete, average_increase}) => ({
      totals_for: kind === 'master' ? 'master' : null,
      master,
      state: kind === 'master' ? '' : state,
      council: kind === 'master' ? '' : council,
      local: kind === 'master' ? '' : local,
      name: kind === 'master' ? '' : name,
      stat,
      members: total_adjusted_members,
      members_not_in_negotiation: adjusted_members_not_in_negotiation,
      complete,
      average_increase
    }));
  }
}

window.customElements.define('masters-report', MasterReport);


export class DetailReport extends ReportSheet {
  getConditions() { return ''}
  getFilter() { return ''}

  constructor() {
    super();

    this.report = {
      name: 'Detail',
      args: [],
      sort: [],
      limit: 16000,
      offset: 0,
      row_func: true,
      columns: this.columns
    };

    this.limit = 15000;
    this.offset = undefined;

  }
  get query() {
    console.log("query");
    let q = gql`
    query detail($limit: Int, $offset: Int, $period: Int) {
        report: md_v_report_detail (
          ${this.getConditions()}
          offset: $offset, 
          limit: $limit,
          where: {_and: [
            {year: {_eq: $period}},
            ${this.getFilter()}
          ]}
        )
        {
          totals_for
					g_affiliate
			    year
			    state
			    council
			    local
			    subunit
			    name
			    members
			    members_not_in_neg
			    increase_pct
			    mbr_x_inc
        }
      }
    
    `
		console.log("QUERY IS ", q);
		return q;
  }
			//year	agreement_id	state	affiliate affiliate_type affiliate_number	agreement	council	local subunit unit_list	stat	in_negotiation members	members_not_in_negotiation	complete	average_increase

//totals_for	period_id	agreement_id	state	affiliate	agreement	council	unit	stat	members	members_not_in_negotiation	complete	average_increase
  columns = [
      { c: 'State', t: 'ltext', total: true },
      //{ c: 'affiliate', t: 'ltext', total: true },
      { c: 'Council', t: 'ltext', total: true },
      { c: 'Local', t: 'ltext', total: true },
      { c: 'Subunit', t: 'ltext', total: true },
      { c: 'Name', t: 'ltext', total: true },
      //{ c: 'units', t: 'ltext', total: true },
      //{ c: 'stat', t: 'number' },
      //{ c: 'in_negotiation', t: 'ctext' },
      { c: 'Members', t: 'number' },
      { c: 'Members not in Negotiation', t: 'number' },
      //{ c: 'complete', t: 'pct' },
      { c: 'Percentage Increase', t: 'pct' },
      { c: 'Members × Increase', t: 'decimal' },
      // members X increase
    ];

  download_columns = this.columns;
  
  queryVars() {
    return {period: this.period_id, offset: this.offset, limit: this.limit};
  }

  dataFunc(raw_data) {
    console.log("MASTER DATA FUNC", raw_data);
    let summary = raw_data.find(d => d.totals_for === 'global');
    if (summary) {
      this.report = {...this.report, summary: [
        {value: 'GRAND TOTAL', colspan: 5, t: 'ltext'},
        //{value: summary.stat, t: 'number'},
       // {value: summary.in_negotiation, t: 'ctext'},
        {value: summary.members, t: 'number'},
        {value: summary.members_not_in_neg, t: 'number'},
        //{value: summary.complete, t: 'pct'},
        {value: summary.increase_pct, t: 'pct'},
        {value: summary.mbr_x_inc, t: 'decimal'},
      ]
      }
    }

    return raw_data.filter(d => d.totals_for !== 'global').map(
			(
			{
					totals_for,
					state,
					name,
					council,
					local,
					subunit,
					g_affiliate,
					//unit_list,
					//stat,
					//in_negotiation,
					members,
					members_not_in_neg,
					//complete,
					increase_pct,
					mbr_x_inc
				}) => ({
      totals_for,
      'State': state,
      'Name': name,
      'Affiliate': g_affiliate,
       affiliate: g_affiliate,
      'Council': council,
      'Local': local,
      'Subunit': subunit,
      //'units': unit_list?.map(({local, subunit}) => `L${local}${subunit ? ` / ${subunit}` : ''}`).join(', '),
      //stat,
      //in_negotiation: in_negot ? 'TRUE' : '',
      'Members': members,
      'Members not in Negotiation': members_not_in_neg,
      //complete,
      'Percentage Increase': increase_pct,
      'Members × Increase': mbr_x_inc
    }));
  }
}

window.customElements.define('detail-report', DetailReport);

